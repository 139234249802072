import {  SearchResponse } from '@app-core/models';

export class StartConnectionAction {
  static readonly type = '[Search] Start SignalR Connection';
}
export class StopConnectionAction {
  static readonly type = '[Search] Stop SignalR Connection';
}
export class InitSignalRConnectionSuccessAction {
  public static readonly type = '[Search] Init SignalR Connection Success';
}
export class ChangeTicketsShowCountAction {
  public static readonly type = '[Search] Change Tickets Show Count';
}

export class SearchRequestAction {
  public static readonly type = '[Search] Search Request';
  // constructor(public readonly value: SearchForm) {}
}
export class SearchResponseClearByTimerAction {
  public static readonly type = '[Search] Search Response Clear By Timer Action';
  constructor(public readonly ms: number) {}
}
export class SearchResponseAction {
  public static readonly type = '[Search] Search Response';
  constructor(public readonly response: SearchResponse) {}
}
export class MobileSearchAgainAction {
  public static readonly type = '[Search] Mobile Search Again';
}
export class GetPresetSearchValueAction {
  public static readonly type = '[Search] Get Preset Search Value';
  constructor(public readonly variant: string) {}
}
export class ValidateAndPrefillSearchFormAction {
  public static readonly type = '[Search] Prefill Search Value';
  constructor(public readonly searchImmediately: boolean) {}
}
export class NavigateToPresearchAction {
  public static readonly type = '[Search] NavigateToPresearch';
}
