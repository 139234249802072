import {Inject, Injectable} from '@angular/core';
import {Router, RouterStateSnapshot} from '@angular/router';
import {SearchService} from '@app-core/api-services';
import {Configs} from '@app-core/configs/configs';
import {IS_FULL_VERSION_TOKEN} from "@app-core/constants";
import {AirportExtended, AllowedDirection} from '@app-core/entities';
import {LocalStorageService} from '@app-core/local-storage-service/local-storage.service';
import {
  CustomerSearchQuery,
  CustomerTicket,
  ExternalSearchForm,
  PreSearchForm,
  SearchResponse,
} from '@app-core/models';
import {ChangeLanguageSuccess, LayoutState} from '@app-states/layout';
import {GetResourcesSuccess, ResourcesState,} from '@app-states/resources';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {UpdateFormValue} from '@ngxs/form-plugin';
import {Navigate, RouterNavigation} from '@ngxs/router-plugin';
import {Action, NgxsOnInit, Selector, State, StateContext, Store,} from '@ngxs/store';
import {fromHash, retryPromise, toHash} from '@shared/utils';
import moment from 'moment';
import {tap, timer} from 'rxjs';
import {labelFactory, search} from '../../search-page/search-block/utils';
import {SignalRService} from '../../socket/signalr.service';
import {
  DEFAULT_SEARCH_STATE_MODEL,
  PresetAirports,
  SearchStateModel,
  TICKETS_SHOW_COUNT_STEP,
} from './search-state.model';
import {
  ChangeTicketsShowCountAction,
  InitSignalRConnectionSuccessAction,
  MobileSearchAgainAction,
  NavigateToPresearchAction,
  SearchRequestAction,
  SearchResponseAction,
  SearchResponseClearByTimerAction,
  StartConnectionAction,
  StopConnectionAction,
} from './search.actions';

@State<SearchStateModel>({
  name: 'search',
  defaults: DEFAULT_SEARCH_STATE_MODEL,
})
@Injectable()
export class SearchState implements NgxsOnInit {
  public ngxsOnInit({
    patchState,
    dispatch,
  }: StateContext<SearchStateModel>): void {
    dispatch(new StartConnectionAction());
  }

  @Selector()
  public static searchForm(state: SearchStateModel): ExternalSearchForm {
    return state.searchForm.model;
  }
  @Selector()
  public static bookingTimeExpire(state: SearchStateModel): Date | null {
    return state.bookingTimeExpire;
  }
  @Selector()
  public static currentServerDateTime(state: SearchStateModel): Date | null {
    return state.currentServerDateTime;
  }
  @Selector()
  public static dateTimeCreate(state: SearchStateModel): Date | null {
    return state.dateTimeCreate;
  }
  @Selector()
  public static lastRequestId(state: SearchStateModel): number | null {
    return state.lastRequestId;
  }
  @Selector()
  public static isDataLoaded(state: SearchStateModel): boolean {
    return state.dataLoaded;
  }
  @Selector()
  public static tickets({ tickets }: SearchStateModel): CustomerTicket[] {
    return tickets;
  }
  @Selector()
  public static searchStartOnce(state: SearchStateModel): boolean {
    return state.searchStartOnce;
  }
  @Selector()
  public static loadingProgress({
    totalCountOperatorsInLastRequest,
    countLoadedOperatorsInLastRequest,
  }: SearchStateModel) {
    return (
      (countLoadedOperatorsInLastRequest / totalCountOperatorsInLastRequest) *
      100
    );
  }
  @Selector()
  public static ticketsToShowCount(state: SearchStateModel): number {
    return state.ticketsToShowCount;
  }
  @Selector()
  public static searchFormValue(
    state: SearchStateModel
  ): ExternalSearchForm | undefined {
    return state.searchForm.model;
  }
  constructor(
    @Inject(IS_FULL_VERSION_TOKEN) private isFullToken: boolean,
    private readonly signalRService: SignalRService,
    private readonly storageService: LocalStorageService,
    private readonly searchApiService: SearchService,
    private readonly router: Router,
    private readonly store: Store
  ) {}

  @Action(RouterNavigation<RouterStateSnapshot>)
  public navigation(
    { patchState, getState, dispatch }: StateContext<SearchStateModel>,
    { routerState, event }: RouterNavigation
  ) {
    const { url } = routerState;
    if (url.includes('presearch')) {
      const searchVariant = routerState.url.split('/presearch/')[1];
      patchState({ hash: searchVariant });

      // для понять что ресурсы есть и хэш распарсен
      const airports = this.store.selectSnapshot(ResourcesState.airports);
      const groupedAirports = this.store.selectSnapshot(ResourcesState.groupedAirports);
      const allowedDirections = this.store.selectSnapshot(ResourcesState.allowedDictionaries);
      if (airports.length) {
        dispatch([ new UpdateFormValue({
          path: 'search.searchForm',
          value: {
            ...this.mapAndValidatePresearchDataToForm(
              fromHash(searchVariant,airports),
              groupedAirports,
              allowedDirections
            ),
          },
        }),new SearchRequestAction()]);
      }
    } else if (url === '/') {
      patchState({ hash: '', searchStartOnce: false });
    }
  }
  @Action(NavigateToPresearchAction)
  public navigateToPresearchAction({
    patchState,
    getState,
    dispatch,
  }: StateContext<SearchStateModel>) {
    const { searchForm, hash } = getState();
    const formValue: ExternalSearchForm = searchForm.model;
    const oldHash = hash;
    const {
      infantCount,
      classType,
      adultCount,
      airportFrom,
      airportTo,
      returnDate,
      departDate,
    }: ExternalSearchForm = formValue;
    const newHash = toHash(
      infantCount,
      classType,
      adultCount,
      airportFrom,
      airportTo,
      returnDate,
      departDate
    );

    return oldHash.length && oldHash === newHash
      ? dispatch(new SearchRequestAction())
      : dispatch(new Navigate(['presearch', newHash]));
  }

  @Action(UpdateFormValue)
  public updateFormValue(
    { patchState, getState, dispatch }: StateContext<SearchStateModel>,
    { payload }: UpdateFormValue
  ) {
    if (payload.path === 'search.searchForm') {
      const value: ExternalSearchForm = payload.value;
      const valueToSave = this.mapExtFormToPreForm(value);
      valueToSave && this.storageService.set('search.searchForm', valueToSave);
    }
  }
  @Action(GetResourcesSuccess)
  public getResourcesSuccess(
    { patchState, getState, dispatch }: StateContext<SearchStateModel>,
    { payload, groupedAirports }: GetResourcesSuccess
  ) {
    const { presetSearchForm, hash } = getState();
    const language = this.store.selectSnapshot(LayoutState.language);
    const searchFormStorage = this.storageService.get('search.searchForm');
    const calcHash = hash ? fromHash(hash, payload.airports) : null;
    return dispatch([
      new UpdateFormValue({
        path: 'search.searchForm',
        value: {
          ...this.mapAndValidatePresearchDataToForm(
            calcHash || searchFormStorage || {...presetSearchForm,airportFromId: this.isFullToken ? PresetAirports.Moscow : PresetAirports.From},
            groupedAirports,
            payload.allowedDirections
          ),
        },
      }),
      new SearchRequestAction(),
    ]);
  }
  @Action(ChangeLanguageSuccess)
  public changeLanguage({
    dispatch,
    patchState,
    getState,
  }: StateContext<SearchStateModel>) {
    const { presetSearchForm, hash } = getState();
    const resources = this.store.selectSnapshot(ResourcesState.state);
    if (resources.countries.length) {
      const searchFormStorage = this.storageService.get('search.searchForm');
      const calcHash = hash ? fromHash(hash, resources.airports) : null;
      dispatch([
        new UpdateFormValue({
          path: 'search.searchForm',
          value: {
            ...this.mapAndValidatePresearchDataToForm(
              calcHash || searchFormStorage || presetSearchForm,
              resources.groupedAirports,
              resources.allowedDirections
            ),
          },
        }),
      ]);
    }
  }
  @Action(StartConnectionAction)
  public startConnection({
    patchState,
    dispatch,
  }: StateContext<SearchStateModel>) {
    this.signalRService
      .startConnection(
        Configs.signalR.host,
        Configs.signalR.searchHub.hubName
      )
      .then((_) => dispatch(new InitSignalRConnectionSuccessAction()));
    this.signalRService.addListener(
      Configs.signalR.searchHub.recievedResponseMethodName,
      this.searchResponseCallback
    );
  }
  @Dispatch() public initConnectionSuccess = () =>
    new InitSignalRConnectionSuccessAction();
  @Dispatch() public searchResponseCallback = (
    searchResponse: SearchResponse
  ) => new SearchResponseAction(searchResponse);

  @Action(StopConnectionAction)
  public stopConnection({ patchState }: StateContext<SearchStateModel>) {
    return this.signalRService.stopConnection();
  }
  @Action(MobileSearchAgainAction)
  public searchAgain({ patchState }: StateContext<SearchStateModel>) {
    return patchState({
      dataLoaded: false,
      lastRequestId: null,
      searchStartOnce: false,
      totalCountOperatorsInLastRequest: 0,
      countLoadedOperatorsInLastRequest: 0,
    });
  }
  @Action(ChangeTicketsShowCountAction)
  public changeTicketsShowCountAction({
    patchState,
    getState,
  }: StateContext<SearchStateModel>) {
    const valInState = getState().ticketsToShowCount;
    return patchState({
      ticketsToShowCount: valInState + TICKETS_SHOW_COUNT_STEP,
    });
  }

  @Action(InitSignalRConnectionSuccessAction)
  public initSignalRConnectionSuccess({
    patchState,
    dispatch,
  }: StateContext<SearchStateModel>) {
    patchState({
      isConnectionSuccess: true,
    });
    return dispatch(new SearchRequestAction());
  }

  @Action(SearchRequestAction)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public searchRequest({
    patchState,
    getState,
    dispatch,
  }: StateContext<SearchStateModel>) {
    const { apiKey, searchForm, hash, isConnectionSuccess } = getState();
    if (
      !hash ||
      !searchForm.model ||
      !searchForm.model.airportFrom ||
      !isConnectionSuccess
    )
      return;
    const request = this.mapSearchFormtoRequest(
      searchForm.model as ExternalSearchForm,
      !this.isFullToken,
      apiKey
    );


    return retryPromise(() => {
      patchState({
        tickets: [],
        ticketsToShowCount: TICKETS_SHOW_COUNT_STEP,
        lastRequestId: null,
        searchStartOnce: true,
        dataLoaded: false,
      });
      return  this.signalRService.send(
        Configs.signalR.searchHub.sendRequestMethodName,
        request
      );
    }, 3, 11000) // 11 seconds delay between retries


  }
  @Action(SearchResponseClearByTimerAction, { cancelUncompleted: true })
  public clearByTimer({
    patchState,
    getState,
  }: StateContext<SearchStateModel>, {ms}:SearchResponseClearByTimerAction) {
    //2часа = 1000 * 60 * 60 * 2
    return timer(ms).pipe(
      tap((value) =>
        patchState({
          tickets: [],
          searchStartOnce: false,
          lastRequestId: null,
          dataLoaded: false,
        })
      )
    );
  }
  @Action(SearchResponseAction, { cancelUncompleted: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public searchResponse(
    { patchState, getState,dispatch }: StateContext<SearchStateModel>,
    { response }: SearchResponseAction
  ) {
    // TODO возможно попросить с бэка флаг что это первый пакет данных для lastRequestId или нет
    const { ticketsToShowCount, lastRequestId, tickets } = getState();
    if (lastRequestId !== null && lastRequestId !== response.requestId) {
      return;
    }
    const isProviderResultsSentToClientArr = Object.entries(
      response.isProviderResultsSentToClient
    );
    const totalCountOperatorsInLastRequest =
      isProviderResultsSentToClientArr.length;
    const countLoadedOperatorsInLastRequest =
      isProviderResultsSentToClientArr.filter((el) => el[1]).length;
    const dataLoaded =
      totalCountOperatorsInLastRequest === countLoadedOperatorsInLastRequest;
    const newTickets = response.tickets ?(
      lastRequestId !== null && lastRequestId === response.requestId
        ? [...tickets, ...response.tickets]
        : response.tickets) : [...tickets];

    patchState({
      // TODO сформировать билеты
      tickets: newTickets,
      lastRequestId: response.requestId,
      bookingTimeExpire: response.bookingTimeExpire,
      currentServerDateTime: response.currentServerDateTime,
      dateTimeCreate: response.dateTimeCreate,
      totalCountOperatorsInLastRequest,
      countLoadedOperatorsInLastRequest,
      ticketsToShowCount,
      dataLoaded,
    });
    const diffMs = moment(response.bookingTimeExpire).diff(moment(response.currentServerDateTime), 'milliseconds');
    if(diffMs>0){
    dispatch(new SearchResponseClearByTimerAction(diffMs));
    }

    return timer(30000).pipe(
      tap((value) =>
        patchState({
          dataLoaded: true,
        })
      )
    );
  }

  private mapAndValidatePresearchDataToForm(
    data: PreSearchForm,
    groupedAirports: AirportExtended[],
    allowedDirections: AllowedDirection[]
  ): ExternalSearchForm {
    const airportFromVal: AirportExtended = search(
      groupedAirports,
      data.airportFromId,
      'airport',
      'equal',
      'id'
    )[0];
    const airportToVal: AirportExtended = search(
      groupedAirports,
      data.airportToId,
      'airport',
      'equal',
      'id'
    )[0];
    const airportFrom: AirportExtended = {
      ...airportFromVal,
      label: labelFactory(
        airportFromVal.airport,
        airportFromVal.city,
        airportFromVal.country,
        true
      ),
    };
    const airportTo: AirportExtended = {
      ...airportToVal,
      label: labelFactory(
        airportToVal.airport,
        airportToVal.city,
        airportToVal.country,
        true
      ),
    };

    const validateDate = (
      departDate: Date | string,
      returnDate: Date | string | null
    ): { departDate: Date | string; returnDate: Date | string | null } => {
      const format: string = 'DD.MM.YYYY';
      const now = moment().format(format);
      const dateIsBeforeNow = moment(departDate, format).isBefore(moment(now,format));
      const diffDates = returnDate
        ? moment(returnDate, format).diff(moment(departDate, format), 'days')
        : 0;
      const newDepartDate =
        !moment(departDate, format).isValid() || dateIsBeforeNow
          ? moment().add(1, 'day').toDate()
          : moment(departDate, format).toDate();
      return {
        departDate: newDepartDate,
        returnDate: returnDate
          ? moment(newDepartDate).add(diffDates, 'days').toDate()
          : null,
      };
    };

    // TODO добавить валидацию дат и направлений направлений
    return {
      adultCount: data.adultCount,
      airportFrom,
      airportTo,
      classType: data.classType,
      infantCount: data.infantCount,
      ...validateDate(data.departDate, data.returnDate),
    };
  }

  private mapSearchFormtoRequest(
    formVal: ExternalSearchForm,
    isGe:boolean,
    apiKey: string
  ): CustomerSearchQuery {
    return {
      departDate: moment(formVal.departDate).format('YYYY-MM-DD'),
      returnDate: formVal.returnDate
        ? moment(formVal.returnDate).format('YYYY-MM-DD')
        : formVal.returnDate,
      cityFromId:
        typeof formVal.airportFrom === 'string'
          ? null
          : formVal.airportFrom.airport.cityId,
      airportFromId:
        typeof formVal.airportFrom === 'string'
          ? null
          : formVal.airportFrom.airport.id,
      cityToId:
        typeof formVal.airportTo === 'string'
          ? null
          : formVal.airportTo.airport.cityId,
      airportToId:
        typeof formVal.airportTo === 'string'
          ? null
          : formVal.airportTo.airport.id,
      adults: formVal.adultCount,
      infants: formVal.infantCount,
      classType: formVal.classType,
      apiKey,
      isGe
    };
  }
  private mapExtFormToPreForm(
    formVal: ExternalSearchForm
  ): PreSearchForm | null {
    if (!formVal.airportFrom || !formVal.airportTo) return null;
    const language = this.store.selectSnapshot(LayoutState.language);
    const format: string = 'DD.MM.YYYY';
    return {
      departDate: formVal.departDate
        ? moment(formVal.departDate).format(format)
        : moment().format(format),
      returnDate: formVal.returnDate
        ? moment(formVal.returnDate).format(format)
        : null,
      airportFromId:
        typeof formVal.airportFrom === 'string'
          ?  this.isFullToken ? PresetAirports.Moscow : PresetAirports.From
          : formVal.airportFrom.airport.id,
      airportToId:
        typeof formVal.airportTo === 'string'
          ? PresetAirports.To
          : formVal.airportTo.airport.id,
      adultCount: formVal.adultCount,
      infantCount: formVal.infantCount,
      classType: formVal.classType,
    };
  }
}
