import {Injectable} from '@angular/core';
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import * as signalR from '@microsoft/signalr';

@Injectable()
export class SignalRService {
  private hubConnection!: signalR.HubConnection;

  public startConnection = (
    host: string,
    hubName: string,
    connectionSuccess?: () => void,
    connectionError?: (err: any) => void,
    logLevel: signalR.LogLevel = signalR.LogLevel.Information
  ) => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${host}/${hubName}`)
      .configureLogging(logLevel)
      .build();
    async function start(hubConnection:HubConnection) {
      try {
        await hubConnection.start();
        console.log("WS Connected.");
      } catch (err) {
        setTimeout(() => start(hubConnection), 10000);
      }
    }
    this.hubConnection.onclose(async () => {
      await start(this.hubConnection);
    });
    return start(this.hubConnection)
      .then(connectionSuccess)
      .catch(connectionError);
  };

  public stopConnection = (): Promise<void> => {
    return this.hubConnection.stop();
  };

  public addListener = <TSource>(
    methodName: string,
    reciever: (data: TSource) => void
  ) => this.hubConnection.on(methodName, reciever);

  public send = <TSource>(methodName: string, data: TSource): Promise<void> =>
    this.hubConnection.send(methodName, data);
}
